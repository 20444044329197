<template>
    <Card title="Inbox">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th colspan="99">
                        <div class="float-start">
                            <div class="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" class="btn btn-sm" :class="{'btn-outline-success': !isCheckAll(), 'btn-outline-danger': isCheckAll()}" @click="CheckAll()">
                                    <i class="bi" :class="{'bi-check-lg': !isCheckAll(), 'bi-dash-lg': isCheckAll()}"></i>
                                </button>
                            </div>
                            <div class="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" class="btn btn-outline-danger btn-sm" @click="remove('')" :disabled="messages.every(m => m.isCheck === false)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <div class="btn-group me-2" role="group" aria-label="First group">
                                <button type="button" class="btn btn-outline-primary btn-sm" @click="getData()">
                                    <i class="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <div class="float-end">
                            <ul class="pagination pagination-sm">
                                <li class="page-item disabled">
                                    <a class="page-link">&laquo;</a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link">4</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link">5</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link">&raquo;</a>
                                </li>
                            </ul>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message.id">
                    <td>
                        <div class="icheck-primary">
                            <input type="checkbox" class="form-check-input" v-model="message.isCheck">
                        </div>
                    </td>
                    <td class="mailbox-name"><router-link :to="{ name: 'AdminReadMail', params: { id: message.id } }">{{ message.name }}</router-link></td>
                    <td class="mailbox-subject"><b>{{ message.subject }}</b> - {{ getText(message.text) }}
                    </td>
                    <td class="mailbox-date">{{ getFromDate(message.createAt.seconds * 1000) }}</td>
                </tr>
            </tbody>
        </table>
    </Card>
</template>

<script>
import moment from 'moment'
import firebase from '@/firebase'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    data() {
        return {
            messages: [],
        }
    },
    methods: {
        getData() {
            firebase.firestore().collection('messages').get().then(({ docs }) => {
                this.messages = docs.map(doc => { return { ...doc.data(), isCheck: false, id: doc.id } })
                console.log(docs)
            })
        },
        Open(message) {
            console.log(message)
        },
        remove() {
            this.messages.forEach(({ id, isCheck }) => {
                if(isCheck) firebase.firestore().collection('messages').doc(id).delete().then(() => console.log(`delete - ${id}`)).catch(err => console.log(err))
            })
        },
        getText(text, lenght = 40) {
            if(text.length <= lenght) return text
            else return `${text.slice(0, lenght).trim()}...`
        },
        isCheckAll() {
            return this.messages.every(m => m.isCheck === true)
        },
        CheckAll() {
            const isCheck = this.isCheckAll()
            this.messages.forEach(m => m.isCheck = !isCheck)
        },
        getDate(milliseconds, format = 'LL - HH:MM') {
            if (!milliseconds)
                return '';
            return moment(milliseconds).utc().format(format);
        },
        getFromDate(milliseconds) {
            if (!milliseconds)
                return '';
            return moment(milliseconds).fromNow();
        }
    },
    mounted() {
        firebase.firestore().collection('messages').onSnapshot(({ docs }) => {
            this.messages = docs.map(doc => { return { ...doc.data(), isCheck: false, id: doc.id } })
            console.log(docs)
        })
    }
}
</script>

<style scoped>
.page-link {
    cursor: pointer;
}
</style>